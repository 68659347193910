.hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: max(calc(100vw * 1498/1920), 780px);
    overflow: visible;
    padding: 0;
}

.hero .logo {
    position: absolute;
    width: auto;
    height: 150px;
    left: 10vw;
    top: 200px;
}

.hero button {
    position: absolute;
    top: 420px;
    left: 10vw;
}

.tagline {
    position: absolute;
    top: 350px;
    left: 10vw;
    font-size: 20px;
}

section {
    padding: 0 3em 3em;
    min-height: 300px;
}

h2 {
    /* Headings */

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin: 16px auto;
    /* Text Color */

    color: #293241;
}

h3 {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-top: -16px;
    /* Theme Color */

    color: #EE6C4D;
}

.accent {
    color: #EE6C4D;
    margin-bottom: -70px;
    margin-left: 45px;
    transform: rotate(-10deg);
    width: 100px;
}

.two-rows-section {
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    margin-top: 50px;
}

.two-rows-section h2 {
    font-size: 24px;
    z-index: 2;
}


section p {
    max-width: 500px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.column {
    flex: 1;
    margin-right: 100px;
    min-width: calc(50% - 100px);
}

.icon {
    max-width: 200px;
    float: right;
    margin-right: 50px;
    margin-top: -50px;
    z-index: 0;
}

img {
    /* position: absolute; */
    /* bottom: 0; */
    /* right: 0; */
    /* max-height: 100%; */
    max-width: 100%;
}

button {
    /* Auto layout */

    padding: 5px 2em;
    height: 42px;

    background: #EE6C4D;
    color: white;
    text-transform: uppercase;
    /* font-weight: 700; */
    border-radius: 5px;
    border: none;
}
button:hover {
    background-image: linear-gradient(rgba(193, 0, 0, 0.22) 0 0);
}

@media screen and (max-width: 1024px) {
    .auto-hide {
        display: none;
    }

    .hero button {
        position: absolute;
        top: 75vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .hero .logo {
        position: absolute;
        width: auto;
        height: 100px;
        left: 10vw;
        top: 200px;
    }

    .tagline {
        position: absolute;
        top: 300px;
        left: 10vw;
        font-size: 20px;
    }

    .column {
        flex: 1;
        margin-right: 20px;
        min-width: calc(50% - 20px);
    }

    .two-rows-section {
        padding-top: 3em;
        margin-top: 50px;
    }

                                                                                                                                .icon {
                                                                                                                                    margin-right: 0px;
                                                                                                                                    margin-left: -200px;
                                                                                                                                }
}

@media screen and (max-width: 768px) {
    .hero .logo {
        top: 90px;
        font-size: 72px;
        margin-top: 0px;
    }

    .tagline {
        top: 180px;
    }

    .row {
        flex-direction: column;
    }

    .column {
        flex: none;
        margin: 0;
        width: 100%;
    }

    .two-rows-section {
        margin: 0;
    }

    .two-rows-section .column {
        margin-top: 100px;
    }

}