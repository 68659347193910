footer {
  width: 100%;
  /* Text Color */
  color: white;
  font-size: 0.8em;
  background: #293241;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
}
